import React, { useState } from "react";
import { Link, graphql, navigate } from "gatsby";
import { Col } from "react-bootstrap";

//components
import useSetPageData from "../utils/useSetPageData";
import { addTrailingSlash } from "../utils/utilities";
import PanelFoundation from "../components/panels/PanelFoundation";
// import Computer from "../components/animations/computer";
import ComponentHeader from "../components/panels/ComponentHeader";
import SEO from "../components/seo";
import Video from "../components/non-panels/react-modal-video/normal-video";

// // import FeaturedArticles from "../components/in-pages/featuredArticles";

// //cards
import ArtificialIntelligenceCard from "../components/non-panels/cards/ArtificialIntelligence";
import RpaCard from "../components/non-panels/cards/RPA";
import ChatbotsCard from "../components/non-panels/cards/Chatbots";
import Partners from "../components/panels/Partners";
// import Testimonies from "../components/non-panels/Testimonies";

//style
import s from "./index.module.scss";
// // import layoutStyle from "../components/layouts/layout.module.scss";

//images
// import computerGif from "../images/home-computer-reduced2.gif";
import playButton from "../images/play-button-2138735_640.png";
import introVideoThumbnail from "../images/intro-video-thumbnail.jpg";
import TabToAccordion from "../components/panels/TabToAccordion";

// utils
import SalesIntelScript from "../utils/SalesIntelScript";

const IndexPage = ({ location, data }) => {
  useSetPageData(location);

  const {
    main_heading,
    main_subheading,
    main_conversion_link_url,
    main_conversion_link_text,
    we_deliver
  } = data.strapiHomePage;

  // const [isVideoModelOpen, setIsVideoModelOpen] = useState(false);

  // const featuredArray = [
  //   ...data.allStrapiPostBlog.edges.map(item => {
  //     item.node.type = "Blog";
  //     item.node.url = addTrailingSlash(`/blog/${item.node.slug}`);
  //     return item.node;
  //   }),
  //   ...data.allStrapiPostCase.edges.map(item => {
  //     item.node.type = "Case Study";
  //     item.node.url = addTrailingSlash(`/case-studies/${item.node.slug}`);
  //     return item.node;
  //   }),
  //   ...data.allStrapiPostNews.edges.map(item => {
  //     item.node.type = "News";
  //     item.node.url = addTrailingSlash(`/news/${item.node.slug}`);
  //     return item.node;
  //   }),
  //   ...data.allStrapiPostWebinar.edges.map(item => {
  //     item.node.type = "Webinar";
  //     item.node.url = addTrailingSlash(`/webinar/${item.node.slug}`);
  //     return item.node;
  //   }),
  //   ...data.allStrapiPostWhitePapers.edges.map(item => {
  //     item.node.type = "White Paper";
  //     item.node.url = addTrailingSlash(`/whitepapers/${item.node.slug}`);
  //     return item.node;
  //   })
  // ];

  // const featuredCardClickHandler = url => {
  //   navigate(url);
  // };

  return (
    <>
      <SalesIntelScript />
      <SEO
        {...{
          title: "Technology Solutions Provider - AI, RPA, and More",
          description: data.strapiHomePage.SEO.description
        }}
      />
      <PanelFoundation
        rowClassName={`${s.spaceAboveHook}`}
        rowStyle={{ marginBottom: "4rem" }}
      >
        <Col lg={7} md={12}>
          <Video
            channel={"vimeo"}
            ratio="16:9"
            autoplay={1}
            allowFullScreen={true}
            videoId={`526327463`}
          // url={`http://vimeo.com/manage/videos/526327463`}
          />
          {/* <div className={`${s.thumbnailWraper}`}
            onClick={() => setIsVideoModelOpen(true)}>

            <img
              src={introVideoThumbnail}
              className={`mb-3`}
              style={{
                width: "100%"
              }}
            />
            <img src={playButton} className={s.playButton} />
          </div> */}
        </Col>
        <Col lg={5} md={12}>
          <div className={s.salePoint}>
            <h1 className={`text-primary mb-5`}>{main_heading}</h1>
            <p className={`text-justify text-muted mb-5`}>{main_subheading}</p>
            <Link
              className={`btn btn-secondary btn-lg`}
              to={main_conversion_link_url}
            >
              {main_conversion_link_text}
            </Link>
          </div>
        </Col>
      </PanelFoundation>
      <ComponentHeader
        // style={{ marginTop: "1rem" }}
        lineTop={false}
        rootClassName={`bg-white ${s.header}`}
        textClassName={`text-primary`}
        text="What We Deliver"
      />
      <TabToAccordion data={we_deliver} />
      <ComponentHeader
        // style={{ marginTop: "1rem" }}
        lineTop={false}
        rootClassName={`bg-white ${s.header} mt-5 pt-5`}
        textClassName={`text-primary ${s.solutionHeader}`}
        text="Featured Solutions"
      />
      <PanelFoundation rootClassName={`panel-padding pt-0 pb-5 mb-4`}>
        <Col lg={4} md={4} sm={12}>
          <ArtificialIntelligenceCard />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <ChatbotsCard />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <RpaCard />
        </Col>
      </PanelFoundation>
      {/* <ComponentHeader
        // style={{ marginTop: "1rem" }}
        lineTop={false}
        rootClassName={`bg-white ${s.header}`}
        textClassName={`text-primary`}
        text="Featured Insights"
      />
      <PanelFoundation rootClassName={`panel-padding pt-0`}>
        {featuredArray.map((featured, i) => (
          <div
            className={`col-lg-6 offset-lg-0 col-md-10 offset-md-1 col-sm-12`}
            onClick={() => featuredCardClickHandler(featured.url)}
          >
            <div
              className={`card card-body ${s.insightCard}`}
              style={{
                backgroundImage: `url(${featured.cover_image.childImageSharp.fluid.src})`
              }}
            >
              <div
                className={`${s.insightCaption} p-5`}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.732)",
                  // background:
                  //   "linear-gradient(23deg, rgba(89, 18, 16, 0.9) 0%, rgba(220, 74, 70, 0.9) 100%)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "auto",
                  borderRadius: "10px 10px 0 0"
                }}
              >
                <div
                  className={`${s.insightType}`}
                  style={{ right: "10px", left: "auto" }}
                >
                  {featured.type}
                </div>
                <h4 className={`text-white`} style={{ paddingTop: "75px" }}>
                  {featured.heading}
                </h4>
                <Link
                  to={addTrailingSlash(featured.url)}
                  className={`text-white`}
                  style={
                    {
                      // position: "absolute",
                      // bottom: "25px",
                      // left: "10px"
                    }
                  }
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </PanelFoundation> */}

      {/* <ComponentHeader
        // style={{ marginTop: "1rem" }}
        lineTop={false}
        rootClassName={`${s.header} pt-0`}
        textClassName={`text-primary`}
        text="Testimonials"
      /> */}
      {/* <PanelFoundation rootClassName={`panel-padding pt-1 mb-4`}>
        <Col
          lg={6}
          sm={12}
        >
        <div className={`col-lg-8 offset-lg-2 col-sm-8 offset-sm-2`}>
          <div className={`${s.cardStack}`} />
          <div className={`${s.cardStack}`} />
          <div className={`${s.cardStack}`} />
          <div className={`${s.cardStack}`} />
          <div className={`${s.cardStack}`} />
          <Testimonies rootClassName={`${s.cardStackTop}`} />
        </div>
        </Col>
      </PanelFoundation> */}
      <ComponentHeader
        // style={{ marginTop: "1rem" }}
        lineTop={false}
        rootClassName={`bg-white ${s.header}`}
        textClassName={`text-primary`}
        text="Partners"
      />
      <Partners />
      {/* <ModalVideo
        channel={"vimeo"}
        ratio="16:9"
        autoplay={1}
        allowFullScreen={true}
        videoId={`526327463`}
        // url={`http://vimeo.com/manage/videos/526327463`}
        isOpen={isVideoModelOpen}
        onClose={() => setIsVideoModelOpen(false)}
      /> */}
      {/* <div
        className={`${layoutStyle.containerFluid} ${layoutStyle.panelPadding} ${indexStyles.noBottomPaddingOnMobile}`}
      >
        <div className={layoutStyle.container}>
          <div
            className={`${layoutStyle.row} ${indexStyles.spaceAboveHook}`}
            style={{ marginBottom: "4rem" }}
          >
            <div className={`${layoutStyle.colLg6} ${layoutStyle.colMd12}`}>
              <img
                src={computerGif}
                style={{
                  width: "100%"
                }}
              />
            </div>
            <div className={`${layoutStyle.colLg6} ${layoutStyle.colMd12}`}>
              <div className={indexStyles.salePoint}>
                <h1>{main_heading}</h1>
                <p className={`${layoutStyle.textJustify}`}>
                  {main_subheading}
                </p>

                <Link
                  className={`${layoutStyle.btn} ${layoutStyle.btnPrimary} ${layoutStyle.btnLg}`}
                  to={main_conversion_link_url}
                >
                  {main_conversion_link_text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <ComponentHeader
        style={{ marginTop: "1rem" }}
        bgColor="white"
        textColor="purple"
        text="What We Deliver"
        className={`${indexStyles.header}`}
      />
      <TabToAccordion data={we_deliver}/>
      <ComponentHeader
        style={{ marginTop: "1rem" }}
        bgColor="white"
        textColor="purple"
        text="Featured Services"
        className={`${indexStyles.header}`}
      />
      <div
        className={`${layoutStyle.containerFluid} ${layoutStyle.panelPadding}`}
      >
        <div className={layoutStyle.container}>
          <div className={layoutStyle.row}>
            <div className={`${layoutStyle.colLg4} ${layoutStyle.colMd4}`}>
              <ServicesAI bgClassName={`${indexStyles.card}`} />
            </div>
            <div className={`${layoutStyle.colLg4} ${layoutStyle.colMd4}`}>
              <ServicesVirtualAssistant bgClassName={`${indexStyles.card}`} />
            </div>
            <div className={`${layoutStyle.colLg4} ${layoutStyle.colMd4}`}>
              <ServicesRPA bgClassName={`${indexStyles.card}`} />
            </div>
          </div>
        </div>
      </div>
      <ComponentHeader
        style={{ marginTop: "1rem" }}
        bgColor="white"
        textColor="purple"
        text="Featured Insights"
        className={`${indexStyles.header}`}
      />

      <div
        className={`${layoutStyle.containerFluid} ${layoutStyle.panelPadding}`}
      >
        <div className={layoutStyle.container}>
          <div className={layoutStyle.row}>
            {featuredArray.map((featured, i) => (
              <div
                className={`${layoutStyle.colLg6} ${layoutStyle.colSm12}`}
                onClick={() => featuredCardClickHandler(featured.url)}
              >
                <div
                  className={` ${layoutStyle.card} ${layoutStyle.cardBody} ${indexStyles.insightCard}`}
                  style={{
                    backgroundImage: `url(${featured.cover_image.childImageSharp.fluid.src})`
                  }}
                >
                  <div className={`${indexStyles.insightType}`}>
                    {featured.type}
                  </div>
                  <div className={`${indexStyles.insightCaption}`}>
                    <h3 className={`${layoutStyle.textWhite}`}>
                      {featured.heading}
                    </h3>
                    <Link
                      to={featured.url}
                      className={`${layoutStyle.textWhite}`}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ComponentHeader
        style={{ marginTop: "1rem" }}
        bgColor="white"
        textColor="purple"
        text="Our Partners"
        className={`${indexStyles.header}`}
      />

      <Partners /> */}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    strapiHomePage {
      SEO {
        description
      }
      main_heading
      main_subheading
      main_conversion_link_url
      main_conversion_link_text
      we_deliver {
        heading
        content
      }
    }

    allStrapiPostBlog(filter: { featured: { eq: true } }) {
      edges {
        node {
          heading
          slug
          cover_image: blog_cover_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allStrapiPostCase(filter: { featured: { eq: true } }) {
      edges {
        node {
          heading
          slug
          cover_image: case_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allStrapiPostNews(filter: { featured: { eq: true } }) {
      edges {
        node {
          heading
          slug
          cover_image: media_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allStrapiPostWebinar(filter: { featured: { eq: true } }) {
      edges {
        node {
          heading
          slug
          cover_image: webinar_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allStrapiPostWhitePapers(filter: { featured: { eq: true } }) {
      edges {
        node {
          heading
          slug
          cover_image: white_papers_media_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`;
