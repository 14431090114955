import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ComponentHeader from "./ComponentHeader";

//styles
import s from "./Partners.module.scss";

export default () => {
  const {
    strapiPaCompany: { partners }
  } = useStaticQuery(graphql`
    query {
      strapiPaCompany {
        partners {
          alt
          partners_image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
        }
      }
    }
  `);
  return (
    <>
      {/* <ComponentHeader
        style={{ marginTop: "1rem" }}
        bgColor="white"
        textColor="purple"
        text="Our Partners"
      /> */}
      <div className={`continer-fluid mb-5`}>
        <div className={`container`}>
          <div className={`row`}>
            {partners.map(
              ({
                partners_image: {
                  childImageSharp: { fluid }
                },
                link,
                alt
              }) => (
                <div className={`col-sm-12 col-md-6 col-lg-4 mb-5`}>
                  <div
                    className={s.partnerContainer}
                  >
                    <a
                      href={link}
                      className={`${s.partner}`}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      style={{ border: "2px solid var(--gray-400)" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Img className={s.image} fluid={fluid} alt={alt} />
                      </div>
                    </a>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
