import React from "react";
import { Helmet } from "react-helmet";

const SalesIntelScript = () => {
  return (
    <Helmet>
      <script charset="utf-8" type="text/javascript" src="https://api-gateway.scriptintel.io/service/visitorintel/visitorTag/221764/script.js?apiKey=MjIxNzY0NDMyOWE4NDUtZDMxYy00MjEwLThkYzEtYTM5NzNlNTFjYjVj"></script>
    </Helmet>
  );
};

export default SalesIntelScript;
