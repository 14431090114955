import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { useTransitionHeight } from "../../utils/useMeasure";
import { isMobile } from "react-device-detect";


//styles
import s from "./TabToAccordion.module.scss";

function TabToAccordion({data}) {
  // const data = [
  //   {
  //     heading: "Optimized Integration",
  //     content:
  //       "Gain fully optimized services and solutions for your business needs through Nitco. As the world is transforming into the digital age, become a leading business in your industry with custom technology integrations that bring more value to your organization.",
  //     link: "link"
  //   },
  //   {
  //     heading: "Process Enhancement",
  //     content:
  //       "Leave inefficient process in the past and gain enhanced processes through Nitco’s AI and automation services. Bring digital technologies into your business operations and improve performance while securing future scalability.",
  //     link: "link"
  //   },
  //   {
  //     heading: "End-To-End support",
  //     content:
  //       "Allow Nitco professionals to implement cutting edge technology solutions into your business operations without hassle. We provide specialists to assist your company’s transition and provide support at each step so you worry less, and flourish more.",
  //     link: "link"
  //   },
  //   {
  //     heading: "Best Practices",
  //     content:
  //       "Offering our expertise, we can provide assistance in improving your company operations and enhance the value produced by your processes. With our insight, gain a partner to set your business up for future success through establishing a foundation of best practices.",
  //     link: "link"
  //   },
  //   {
  //     heading: "Strategized Implementation",
  //     content:
  //       "From proof of concept to pushing to production, our specialists ensure a thorough end-to-end strategy to implement our digital solutions into your existing environment with little to no interruption. Nitco provides in-depth implementation plans to improve your applications and processes. We strive to enhance efficiency across your organization bringing greater business agility and value.",
  //     link: "link"
  //   }
  // ];

  let itemStates = new Array(data.length).fill(false);
  itemStates[0] = true;

  let toggleArray = [];

  const [itemSelect, setItemSelect] = useState(itemStates);

  const itemClickHandler = index => {
    if (!isMobile) {
      setItemSelect(
        itemSelect.map((item, i) => {
          const isOpened = i === index ? true : false;
          return isOpened;
        })
      );
    } else {
      setItemSelect(
        itemSelect.map((item, i) => {
          const isOpened = i === index ? !item : item;
          return isOpened;
        })
      );
    }
  };

  //   const handleCollapsibleToggle = (index) => {
  //     setItemSelect(
  //       itemSelect.map((item, i) => {
  //         return i === index ? !item : item;
  //       })
  //     );
  //   };

  return (
    <div
      className={`container-fluid  ${s.bg}  ${s.benefits} mb-5`}
      // ${layoutStyle.panelPadding}
      style={{ padding: 0 }}
    >
      <div className={`${s.benefitsOverlay}`}>
        <div className={`container`}>
          <div className={`row pt-5`}>
            {/* <div className={`${s.benefitsOverlay}`}> */}
            <div
              className={`col-lg-6 col-md-8 ${s.tabletCenter}`}
            >
              <div className={`row`}>
                <div
                  className={`col-lg-12 col-md-12 col-sm-12`}
                >
                  {data &&
                    itemSelect.map((item, i) => {
                      const [
                        style,
                        bind,
                        toggle
                      ] = useTransitionHeight(i === 0);
                      // setToggle(item);
                      const chevronRotate = useSpring({
                        from: {
                          transform: "rotateZ(0deg)"
                        },
                        to: {
                          transform: item ? "rotateZ(90deg)" : "rotateZ(0deg)"
                        }
                      });


                      return (
                        <div key={i}>
                          <h2
                            className={`${s.btn} ${item ? s.active : ""}`}
                            onClick={() => {
                              itemClickHandler(i);
                              toggle();
                            }}
                          >
                            <animated.i
                              className={`fas fa-chevron-right ${s.visibleMdDown}`}
                              style={chevronRotate}
                            ></animated.i>
                            {data[i].heading}
                          </h2>

                          <animated.div
                            style={style}
                            className={`${s.visibleMdDown} ${s.content} mb-5`}
                          >
                            <div {...bind} className={``}>
                              <p className={`${s.para}`}>{data[i].content}</p>
                            </div>
                            {/* {i ===0 && toggle()} */}
                          </animated.div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-6 col-md-12 pb-5 ${s.visibleLg}`}
            >
              <h3 className={`mb-5`}>
                {!isMobile && data[itemSelect.indexOf(true)].heading}
              </h3>

              <p className={`${s.paraDesk} mb-5`}>
                {!isMobile && data[itemSelect.indexOf(true)].content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabToAccordion;
